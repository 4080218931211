<template>
	<ValidationObserver v-slot="{ valid, invalid, validated, passes, validate, handleSubmit, errors }" ref="observer">

		<div class="fill-height  pa-5 pa-sm-10" v-if="started_home" id="home-page"
		     :style='"background: url("+ AppStore.data.configuration[AppStore.locale].img +") no-repeat;background-size: cover;"'>
			<v-container fluid class="fill-height d-flex align-center flex-row">
				<v-row class="text-center" align="center" justify="center">
					<v-card elevation="0" class="mx-auto pa-sm-10" rounded="xl">
						<v-col cols="12" class="text-h1 my-auto" id="home-title"
						       v-html="AppStore.data.configuration[AppStore.locale].title_1">
						</v-col>
						<v-col cols="12" class="text-center" id="home-stars">
							<div class="text-subtitle-1 text-center" id="subtitle" v-if="AppStore.data.configuration[AppStore.locale].subtitle"
							     :style="'color:'+AppStore.data.general.secondary_color.hexa"
							     v-show="!_.includes([1,2,3,4,5], AppStore.rating.rate)"
							     v-html="AppStore.data.configuration[AppStore.locale].subtitle"></div>
							<v-rating v-model="AppStore.rating.rate" @input="EventBus.$emit('launchHomeWatcher', {})">
								<template v-slot:item="props">
									<v-icon
										:style="$vuetify.breakpoint.smAndUp ? 'font-size: 125px;' : 'font-size: 55px;'"
										:color="getRateColor(props.isFilled)"
										small
										class="pa-0 rate-icon"
										@click="props.click">
										{{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
									</v-icon>
								</template>
							</v-rating>
						</v-col>
						<v-col cols="12" id="home-questions"
						       v-if="questions_to_display && questions_to_display.length">
							<div v-for="(question, questionIndex) in questions_to_display"
							     :key="questionIndex+questionKey+updatedKey" class="home-question">
								<divider-view v-if="question.format === 'divider'"></divider-view>
								<slider-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'slider'"
									:rules="question.is_required ? { regex: /^[1-5]+$/ } : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></slider-view>
								<text-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'text'"
									:rules="question.is_required ? 'required' : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></text-view>
								<textarea-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'textarea'"
									:rules="question.is_required ? 'required' : ''"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></textarea-view>
								<select-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'select'"
									:rules="question.is_required ? 'required' : ''"
									:name="questions_to_display[questionIndex].name"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></select-view>
								<checkbox-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'checkbox'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></checkbox-view>
								<radio-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'radio'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></radio-view>
								<switch-view
									:class="{ 'd-none' : questions_to_display[questionIndex].must_be_hide }"
									v-if="question.format === 'switch'"
									:rules="question.is_required ? 'required' : ''"
									:color="AppStore.data.general.primary_color"
									v-model="questions_to_display[questionIndex]"
									:questionIndex="questionIndex" :lang="AppStore.locale"></switch-view>
							</div>
						</v-col>
						<v-col cols="12" v-show="_.includes([1,2,3,4,5], AppStore.rating.rate)" style="min-height:64px">
							<!--						<v-slide-x-transition>-->
							<!--						</v-slide-x-transition>-->
							<!--						<v-slide-x-transition mode="out-in">-->
								<v-btn @click="resetHomepage()" @keypress.enter="navigate" role="link" rounded
								       outlined elevation="0"
								       :large="AppStore.data.general.button_size === 'large'"
								       :x-large="AppStore.data.general.button_size === 'x-large'"
								       :x-small="AppStore.data.general.button_size === 'x-small'"
								       :small="AppStore.data.general.button_size === 'small'"
								       :color="AppStore.data.general.primary_color.hexa"
								       class="text-button mr-0 mr-sm-12 revert-btn"
								       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
								       v-html="AppStore.data.configuration[AppStore.locale].rate_retry_btn_text">
								</v-btn>

								<v-btn @click="passes(submit)" role="link" @keypress.enter="navigate" rounded
								       elevation="0" dark
								       :large="AppStore.data.general.button_size === 'large'"
								       :x-large="AppStore.data.general.button_size === 'x-large'"
								       :x-small="AppStore.data.general.button_size === 'x-small'"
								       :small="AppStore.data.general.button_size === 'small'"
								       :color="AppStore.data.general.primary_color.hexa"
								       class="text-button ml-0 ml-sm-12 mt-3 mt-sm-0"
								       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
								       v-html="AppStore.data.configuration[AppStore.locale].rate_validate_btn_text">
								</v-btn>
							<!--						</v-slide-x-transition>-->
						</v-col>
					</v-card>
				</v-row>
			</v-container>
		</div>
	</ValidationObserver>
</template>

<script>
import {ValidationObserver} from 'vee-validate';
import RatingsCarousel from "../../../components/tb/1/RatingsCarousel.vue";
import AppStore from "../../../stores/AppStore";
import LocaleChanger from "../../../components/LocaleChanger.vue";
import _ from 'lodash';
import {EventBus} from "../../../services/Request";
import Helpers from "../../../services/Helpers";
import SliderView from "../../../components/communs/inputViewer3/SliderView.vue";
import RadioView from "../../../components/communs/inputViewer3/RadioView.vue";
import SelectView from "../../../components/communs/inputViewer3/SelectView.vue";
import TextareaView from "../../../components/communs/inputViewer3/TextareaView.vue";
import SwitchView from "../../../components/communs/inputViewer3/SwitchView.vue";
import CheckboxView from "../../../components/communs/inputViewer3/CheckboxView.vue";
import TextView from "../../../components/communs/inputViewer3/TextView.vue";
import DividerView from "../../../components/communs/inputViewer3/DividerView.vue";

export default {
	name: 'home-view',

	data: () => ({
		AppStore,
		EventBus,
		updatedKey: 0,
		questions: [],
		questions_to_display: [],
		started_home : false,
		questionKey:0
	}),

	components: {
		DividerView, TextView, CheckboxView, SwitchView, TextareaView, SelectView, RadioView, SliderView,
		LocaleChanger,
		RatingsCarousel,
		ValidationObserver
	},

	props: {},

	created() {
		if (!AppStore.started_app) {
			const url = this.$route.path.toString().split("/")
			EventBus.$emit('getApp', {
				type: url[1],
				model: url[2],
				slug: this.$route.params.slug,
				params: this.$route.query,
				noredirect: false
			})
		} else {
			this.startQuestions()
		}
		EventBus.$on('started-app', (data) => {
			this.startQuestions()
		});
	},

	mounted() {
		EventBus.$on('refreshQuestions', (data) => {
			this.questionKey = this.questionKey+1
		});
	},

	computed: {
		Helpers() {
			return Helpers
		},
		_() {
			return _;
		},
	},

	watch: {
	},

	methods: {
		async startQuestions() {
			this.questions = []
			this.questions_to_display = []
			await this.getBookmarkedQuestions()
			if (AppStore.questions_by_page[1] && AppStore.questions_by_page[1].length > AppStore.data.configuration.questions_to_display_1) {
				await this.getRandomQuestions();
			} else {
				this.questions = Object.assign([], _.filter(AppStore.questions_by_page[1], (q) => {
					return !q.is_bookmarked
				}))
				this.questions_to_display = this.questions_to_display.concat(this.questions)
			}
			this.started_home = true
		},

		getBookmarkedQuestions() {
			this.questions_to_display = Object.assign([], AppStore.bookmarkedQuestions[1])
		},

		getRandomQuestions() {
			const max = AppStore.data.configuration.questions_to_display_1 - this.questions_to_display.length
			this.questions = Object.assign([], _.filter(AppStore.questions_by_page[1], (q) => {
				return !q.is_bookmarked
			}))

			for (let i = 0; i < max; i++) {
				const randomQuestionIndex = Math.floor(Math.random() * (this.questions.length));
				this.questions_to_display.push(this.questions[randomQuestionIndex]);
				this.questions.splice(randomQuestionIndex, 1)
			}
		},
		submit() {
			if (AppStore.data.configuration.skip_appreciation_page) {
				this.$router.push({name: 'tb-3-comment'})
			} else {
				this.$router.push({name: 'tb-3-appreciation'})
			}
		},

		getRateColor(isFilled) {
			let color = 'orange'
			if (isFilled) {
				if (!AppStore.data.configuration.override_rate_stars_color && AppStore.data.configuration.default_rate_stars_color.hexa) {
					color = AppStore.data.configuration.default_rate_stars_color.hexa
				} else {
					color = AppStore.data.configuration['override_rate_stars_color_star_' + AppStore.rating.rate].hexa
				}
			} else {
				color = 'grey lighten-0'
			}

			return color
		},

		resetHomepage() {
			AppStore.rating.rate = 0

			_.forEach(questions_to_display, (question, index) => {
				if(!question.is_hidden){
					delete AppStore.rating.questions_answers[question.id]
				}
			})
			this.updatedKey = this.updatedKey+1
		}
	}
}
</script>
<style>

</style>